<template>
  <div>
    <TableAttestatiInsegnanti
      :fromInsegnante="fromInsegnante"
      @getCorsiAreaInsegnanteList="getCorsiAreaInsegnanteList"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import TableAttestatiInsegnanti from "@/components/components-fit/SNM/accesso-insegnante/TableAttestatiInsegnanti.vue";

export default defineComponent({
  name: "corsi-iscrizione",
  // FiltriAttestatiInsegnanti, Gia creato filtro tipo, da vedere
  components: { TableAttestatiInsegnanti },
  props: {
    fromInsegnante: {
      type: Boolean,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    const store = useStore();
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const getCorsiAreaInsegnanteList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_persona: id_persona.value,
          // tipo: "string",
        },
        apiLink: globalApi.SNM_CORSI_ATTESTATI_QUALIFICHE_LIST,
        itemName: "attestati_area_insegnante_list",
      });
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedattestati_area_insegnante_list")
    );

    const searchCorsiAreaInsegnanteList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipCorsiAreaInsegnante");
      getCorsiAreaInsegnanteList();
    };

    getCorsiAreaInsegnanteList();

    return {
      getCorsiAreaInsegnanteList,
      searchCorsiAreaInsegnanteList,
    };
  },
});
</script>
