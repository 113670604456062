<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          corsi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        attestati_area_insegnante_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        ></span
      >
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="attestati_area_insegnante_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsCorsiAreaInsegnante"
        @current-change="setCurrentPageCorsiAreaInsegnante"
      >
        <template v-slot:cell-anno="{ row: data }">{{ data.anno }}</template>
        <template v-slot:cell-data_attestato="{ row: data }"
          ><div class="tab-long">
            {{ data.data_attestato }}
          </div></template
        >
        <template v-slot:cell-luogo="{ row: data }"
          ><div>
            {{ data.luogo }}
          </div></template
        >
        <template v-slot:cell-tipo_attestato="{ row: data }">
          <div class="text-capitalize">{{ data.tipo_attestato }}</div>
        </template>
        <template v-slot:cell-tipologia="{ row: data }"
          ><div class="tab-long" style="min-width: 14rem">
            {{ data.tipologia }}
            <Popper
              v-if="data.descrizione"
              arrow
              :content="
                data.descrizione
                  ? data.descrizione
                  : 'Nessuna descrizione disponibile'
              "
            >
              <i class="bi bi-info-circle-fill text-center ps-2"></i>
            </Popper></div
        ></template>
        <template v-slot:cell-data_ora_download="{ row: data }"
          ><div class="tab-long">
            {{
              data.data_ora_download
                ? format(new Date(data.data_ora_download))
                : ""
            }}
          </div></template
        >
        <template v-slot:cell-scaricato="{ row: data }"
          ><div class="tab-long text-center">
            <span class="text-success" v-if="data.scaricato">SI</span>
            <span class="text-danger" v-else>NO</span>
          </div></template
        >
        <template v-slot:cell-options="{ row: data }">
          <button
            type="button"
            class="badge bg-light-success text-success rounded fs-6"
            @click="stampaAttestatoCorso(data.id_attestato, data.id_persona)"
          >
            STAMPA ATTESTATO
          </button>
          <div class="btn-group">
            <button
              v-if="data.id_iscrizione"
              type="button"
              class="badge bg-light-primary text-primary rounded fs-6 me-4"
              data-bs-toggle="modal"
              :data-bs-target="`#kt_modal_documenti_partecipante${data.id}`"
              @click="
                stagioneCorso = data.stagione;
                id_tipo_corso = data.tipo_corso;
                id_corso = data.id;
                data.viewDocs = true;
              "
            >
              <i class="bi bi-files text-primary fw-bold me-2"></i>Documenti
            </button>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";

import { useRoute } from "vue-router";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";
import services from "@/axios/dbManag";
import { format } from "@/composables/formatDate";

import Popper from "vue3-popper";

export default {
  name: "table-corsi-iscrizioni",
  components: {
    Datatable,
    Loading,
    Popper,
  },
  emits: ["getCorsiAreaInsegnanteList"],
  props: {
    fromInsegnante: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);
    const stagioneCorso = ref(null);
    const id_tipo_corso = ref(null);

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const setFetchRowsCorsiAreaInsegnante = (e) => {
      store.commit("setFetchRowsCorsiAreaInsegnante", e);
      emit("getCorsiAreaInsegnanteList");
    };
    const setCurrentPageCorsiAreaInsegnante = (page) => {
      store.commit("setCurrentPageCorsiAreaInsegnante", page);
      emit("getCorsiAreaInsegnanteList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnCorsiAreaInsegnante", columnName);
      store.commit("setSortOrderCorsiAreaInsegnante", order);
      emit("getCorsiAreaInsegnanteList");
    };

    const refreshList = () => {
      emit("getCorsiAreaInsegnanteList");
    };

    const tableHeader = ref([
      {
        name: "Anno",
        key: "anno",
        sortable: false,
      },
      {
        name: "Data attestato",
        key: "data_attestato",
        sortable: false,
      },
      {
        name: "Luogo",
        key: "luogo",
        sortable: false,
      },
      {
        name: "Tipo Attestato",
        key: "tipo_attestato",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipologia",
        sortable: false,
      },
      {
        name: "Attestato Scaricato",
        key: "scaricato",
        sortable: false,
      },
      {
        name: "Data download",
        key: "data_ora_download",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const isLoaded = ref(false);
    const jsonViewEditCorso = ref({});
    const id_corso = ref(null);

    const isLoading = ref(false);

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    const setTutteLeStagioniCorsiAreaInsegnante = () => {
      store.commit("setTutteLeStagioniCorsiAreaInsegnante");
      emit("getCorsiAreaInsegnanteList");
    };

    const token = store.getters.tokenAdb2c
      ? store.getters.tokenAdb2c
      : localStorage.getItem("tokenAdb2c");

    const stampaAttestatoCorso = (id_attestato, id_persona) => {
      const url = `${services.baseUrl}/snm/corsi/attestati/stampapdf/get?id_attestato=${id_attestato}&id_persona=${id_persona}&request=${token}`;
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = url;
      link.setAttribute("download", `Attestato_${id_attestato}.pdf`);
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 500);
    };

    return {
      stampaAttestatoCorso,
      getFormatDate,
      attestati_area_insegnante_list: computed(() =>
        store.getters.getStateFromName("resultsattestati_area_insegnante_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedattestati_area_insegnante_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordattestati_area_insegnante_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusattestati_area_insegnante_list")
      ),
      currentPage: computed(() => store.getters.currentPageCorsiAreaInsegnante),
      rowsToSkip: computed(() => store.getters.rowsToSkipCorsiAreaInsegnante),
      fetchRows: computed(() => store.getters.fetchRowsCorsiAreaInsegnante),
      sortColumn: computed(() => store.getters.sortColumnCorsiAreaInsegnante),
      sortOrder: computed(() => store.getters.sortOrderCorsiAreaInsegnante),
      setFetchRowsCorsiAreaInsegnante,
      setCurrentPageCorsiAreaInsegnante,
      setSortOrderColumn,
      tableHeader,
      isLoaded,
      jsonViewEditCorso,
      refreshList,
      id_corso,
      id_persona,
      stagioneCorso,
      id_tipo_corso,
      isLoading,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      isEnabled,

      tutteStagioniCorsiAreaInsegnante: computed(
        () => store.getters.tutteStagioniCorsiAreaInsegnante
      ),
      setTutteLeStagioniCorsiAreaInsegnante,
      format,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  text-align: center;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
